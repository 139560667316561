.theme-diablo4 {
	--main-bg: rgb(21, 21, 21);
	--secondary-bg: rgb(31, 31, 31);
	--main-fg: white;
	--accent-1-bg: rgb(199, 13, 13);
	--accent-1-fg: rgb(10, 8, 6);
	--accent-2-bg: rgb(24, 128, 121);
	--accent-2-fg: rgb(223, 220, 217);
}

.theme-valhalla {
	--main-bg: rgb(21, 21, 21);
	--secondary-bg: rgb(31, 31, 31);
	--main-fg: white;
	--accent-1-bg: rgb(199, 13, 13);
	--accent-1-fg: rgb(10, 8, 6);
	--accent-2-bg: rgb(24, 128, 121);
	--accent-2-fg: rgb(223, 220, 217);
}

.theme-wdlegion {
	--main-bg: rgb(21, 21, 21);
	--secondary-bg: rgb(31, 31, 31);
	--main-fg: white;
	--accent-1-bg: rgb(209, 45, 52);
	--accent-1-fg: rgb(10, 8, 6);
	--accent-2-bg: rgb(35, 196, 235);
	--accent-2-fg: rgb(223, 220, 217);
}

.theme-cyberpunk {
	--main-bg: rgb(21, 21, 21);
	--secondary-bg: rgb(31, 31, 31);
	--main-fg: white;
	--accent-1-bg: rgb(248, 239, 28);
	--accent-1-fg: rgb(10, 8, 6);
	--accent-2-bg: rgb(35, 196, 235);
	--accent-2-fg: rgb(223, 220, 217);
}

.article-content-container {
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 1200px;
	text-align: justify;
	margin: 0 auto;
	flex: 1;
	padding-top: 100px;
	color: var(--main-fg);
	padding-bottom: 100px;

	h1 {
		margin-top: 3rem;
	}

	h2 {
		margin-top: 6rem;
	}

	h3 {
		font-weight: 400;
		margin-top: 3rem;
		a {
			color: var(--accent-2-bg);
			gap: 0.5rem;
		}
	}

	a {
		color: var(--accent-2-bg);
		text-decoration: none;
		display: inline-flex;
		align-items: center;
		gap: 0.25rem;
		svg {
			width: 18px;
			height: 18px;
		}
	}
}

#articles {
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 1600px;
	margin: 0 auto;
	flex: 1;
	padding-top: 100px;

	h1 {
		color: var(--main-fg);
	}

	.articles-container {
		display: grid;
		grid-template-columns: 1fr;
		gap: 1rem;

		@media (min-width: 768px) {
			grid-template-columns: 1fr 1fr;
		}

		.article-entry {
			display: flex;
			flex-direction: column;
			background-color: var(--secondary-bg);
			color: var(--main-fg);
			padding: 1rem 2rem;
			border-radius: 16px;
			box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
			transition: transform 0.2s ease-in-out;
			text-decoration: none;

			h2 {
				font-weight: 700;
				font-size: 1.5rem;
			}

			h3 {
				font-weight: 300;
				font-size: 1rem;
			}

			&:hover {
				transform: scale(1.02);
			}
		}
	}
}

footer.articles-footer {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 1rem;
	background-color: var(--accent-1-bg);
	width: calc(100% - 2rem);
	flex-direction: column;

	p {
		max-width: 1600px;
		font-size: 0.8rem;
	}

	ul {
		display: flex;
		flex-direction: row;
		gap: 1rem;
		list-style: none;

		li {
			a {
				color: var(--accent-1-fg);
				text-decoration: none;
				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
}
